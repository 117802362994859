import { useEffect } from "react";

const REFRESH_INTERVAL = 30 * 60 * 1000; // 30 minutes

export function useRefreshWhenStale() {
  useEffect(() => {
    const intervalId = setInterval(() => {
      window.location.reload();
    }, REFRESH_INTERVAL);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
}
