import { getConfig } from "./config";

const INITIAL_RECONNECT_TIME = 1000;
const MAX_RECONNECT_TIME = 10000;
let reconnectTime = INITIAL_RECONNECT_TIME;

export function connect() {
  const { wsUrl, wsToken } = getConfig();
  let heartbeatInterval: NodeJS.Timer | null | undefined;
  const HEARTBEAT_INTERVAL = 30000; // 30 seconds
  const MAX_MISSED_HEARTBEATS = 2;
  let missedHeartbeats = 0;

  if (!wsToken) {
    console.warn("wsToken is not set, skipping ws connection");
    return;
  }

  const ws = new WebSocket(`${wsUrl}/ws/locker/${wsToken}`);

  function sendHeartbeat() {
    if (ws.readyState === WebSocket.OPEN) {
      console.log("↑ sending heartbeat");
      ws.send("h");
      missedHeartbeats++;

      // If we've missed too many heartbeats, close the connection
      if (missedHeartbeats > MAX_MISSED_HEARTBEATS) {
        console.error("Too many missed heartbeats, closing connection");
        ws.close();
      }
    }
  }

  function startHeartbeat() {
    stopHeartbeat(); // Clear any existing interval
    heartbeatInterval = setInterval(sendHeartbeat, HEARTBEAT_INTERVAL);
  }

  function stopHeartbeat() {
    if (heartbeatInterval) {
      clearInterval(heartbeatInterval);
      heartbeatInterval = null;
    }
  }

  ws.onopen = function (e) {
    console.log("ws open", e);
    reconnectTime = INITIAL_RECONNECT_TIME;
    startHeartbeat();
  };

  ws.onmessage = async function (e) {
    const message = e.data;
    if (message === "h") {
      console.log("↓ heartbeat response", message);
      // Reset missed heartbeats counter on acknowledgment
      missedHeartbeats = 0;
      return;
    }

    const parsedMessage = JSON.parse(message);

    if (parsedMessage.event && parsedMessage.event.type === "door.open") {
      const position = parsedMessage.event.door;
      const x = position[0];
      const y = position.substring(1);
      await fetch(`http://127.0.0.1:5000/?xpos=${x}&ypos=${y}`);
    }
  };

  ws.onerror = function (e) {
    console.error("ws error", e);
    stopHeartbeat();
    ws.close();
  };

  ws.onclose = function (e) {
    console.error(`ws closed. Reconnection in ${reconnectTime}ms`, e);
    stopHeartbeat();

    setTimeout(function () {
      reconnectTime = Math.min(reconnectTime * 1.1, MAX_RECONNECT_TIME);
      connect();
    }, reconnectTime);
  };

  window.onbeforeunload = function () {
    if (ws && ws.readyState === ws.OPEN) {
      stopHeartbeat();
      ws.close();
    }
  };

  return {
    close: () => {
      stopHeartbeat();
      ws.close();
    },
  };
}
