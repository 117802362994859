import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

const IDLE_TIMEOUT = 5 * 60 * 1000; // 5 minutes

export function useRedirectHomeOnIdle() {
  const history = useHistory();
  const location = useLocation();
  const [remainingTime, setRemainingTime] = useState(IDLE_TIMEOUT);

  useEffect(() => {
    if (location.pathname === "/") {
      return;
    }

    let timeoutId: NodeJS.Timeout;
    let intervalId: NodeJS.Timeout;

    const resetTimer = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (intervalId) {
        clearInterval(intervalId);
      }

      setRemainingTime(IDLE_TIMEOUT);

      timeoutId = setTimeout(() => {
        history.push("/");
      }, IDLE_TIMEOUT);

      intervalId = setInterval(() => {
        setRemainingTime((prev) => Math.max(0, prev - 1000));
      }, 1000);
    };

    const events = [
      "mousemove",
      "mousedown",
      "keypress",
      "touchstart",
      "touchmove",
    ];

    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    resetTimer();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      if (intervalId) {
        clearInterval(intervalId);
      }
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
    };
  }, [history, location.pathname]);

  return remainingTime;
}
