import { Button, Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Footer } from "../../components/Footer";
import { useState, useCallback } from "react";

export function Help() {
  const history = useHistory();
  const [, setClickCount] = useState(0);

  const handleTitleClick = useCallback(() => {
    setClickCount((prev) => {
      const newCount = prev + 1;
      if (newCount >= 3) {
        window.location.reload();
        return 0;
      }
      return newCount;
    });
  }, []);

  return (
    <div className="position-absolute h-100 w-100 top-0 left-0">
      <div className="d-flex align-items-center p-4">
        <Button
          variant="link"
          className="fs-3 text-decoration-none me-3"
          onClick={() => history.goBack()}
        >
          <i className="bi-arrow-left-circle-fill me-2"></i>
          Retour
        </Button>
        <h1
          className="mb-0"
          onClick={handleTitleClick}
          style={{ cursor: "default" }}
        >
          Aide
        </h1>
      </div>

      <Container className="px-4">
        <section className="mb-4">
          <h2 className="fs-4 mb-3">Navigation</h2>
          <ul className="list-unstyled">
            <li className="mb-2">
              <i className="bi-arrow-left-circle-fill me-2"></i>
              <strong>Retour</strong> - Retourne à la page précédente
            </li>
            <li className="mb-2">
              <i className="bi-house-door-fill me-2"></i>
              <strong>Accueil</strong> - Retourne à la page d'accueil
            </li>
          </ul>
        </section>

        <section className="mb-4">
          <h2 className="fs-4 mb-3">Support</h2>
          <p>
            En cas de problème ou pour toute question, veuillez contacter le
            support technique:
          </p>
          <ul className="list-unstyled">
            <li className="mb-2">
              <i className="bi-telephone me-2"></i>
              Support: <strong>1-833-622-0304</strong>
            </li>
            <li className="mb-2">
              <i className="bi-envelope me-2"></i>
              Email: <strong>info@expedibox.com</strong>
            </li>
            <li className="mb-2">
              <i className="bi-globe me-2"></i>
              Site web: <strong>support.expedibox.com</strong>
            </li>
          </ul>
        </section>
      </Container>

      <Footer />
    </div>
  );
}
