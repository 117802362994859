import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Toast } from "react-bootstrap";
import { Numpad } from "../../components/Numpad";
import useGetWorkers from "../../hooks/useGetWorkers";
import { useGetExpediboxLocker } from "../../hooks/useGetExpediboxLocker";

export function Code() {
  const [code, setCode] = useState("");
  const [invalid, setInvalid] = useState(false);
  const history = useHistory();
  const lockerQuery = useGetExpediboxLocker();
  const workerQuery = useGetWorkers(code, {
    enabled: !!code && code !== "000872",
    staleTime: 0,
    cacheTime: 0,
  });

  useEffect(() => {
    if (code === "000872") {
      if (lockerQuery.data?.test) {
        setInvalid(false);
        history.push("/test");
      } else {
        setInvalid(true);
      }
      return;
    }

    if (workerQuery.data && workerQuery.data.length) {
      setInvalid(false);
      const worker = workerQuery.data[0];
      history.push("/profile", { worker });
    } else if (workerQuery.data) {
      setInvalid(true);
    }
  }, [workerQuery.data, history, code, lockerQuery.data]);

  return (
    <>
      <Toast
        show={invalid}
        onClose={() => setInvalid(false)}
        delay={3000}
        autohide
        className="position-fixed top-25 start-50 translate-middle"
        bg="danger"
        style={{ zIndex: 1000 }}
      >
        <Toast.Body className="text-white text-center fs-4">
          Code invalide. Veuillez réessayer.
        </Toast.Body>
      </Toast>
      <Numpad
        className="mt-4 bg-surface-variant shadow-sm p-4 rounded"
        placeholder={invalid ? "Code Invalide" : "Code Employé"}
        loading={workerQuery.isLoading}
        onSubmit={(code) => setCode(code)}
      />
    </>
  );
}
