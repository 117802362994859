export function initConfig(key: string, hint?: string) {
  const urlParams = new URLSearchParams(window.location.search);
  // first, look in the URL
  let value = urlParams.get(key);

  if (!value) {
    // second, look in local storage
    value = localStorage.getItem(key);
  }

  if (!value) {
    // third, prompt the user
    value = window.prompt(key, hint);
  }

  if (value) {
    localStorage.setItem(key, value);
  } else {
    console.error(`${key} is null`);
  }
}

export function getConfig() {
  const apiUrl = localStorage.getItem("apiUrl") || "";
  const apiKey = localStorage.getItem("apiKey") || "";
  const expediboxApiUrl = localStorage.getItem("expediboxApiUrl") || "";
  const expediboxApiKey = localStorage.getItem("expediboxApiKey") || "";
  const expediboxLockerId = localStorage.getItem("expediboxLockerId") || "";
  const wsUrl = localStorage.getItem("wsUrl") || "wss://ws.xpdbx.co";
  const wsToken = localStorage.getItem("wsToken") || "";
  return {
    apiUrl,
    apiKey,
    expediboxApiUrl,
    expediboxApiKey,
    expediboxLockerId,
    wsUrl,
    wsToken,
  };
}

/**
 * Quick Configuration
 */

if (window.location.search.includes("?dev")) {
  localStorage.setItem("apiUrl", "http://127.0.0.1:8001/api");
  localStorage.setItem("apiKey", "0d8e71ee-c5f4-49a2-a343-81b7ac1ddbea");
  localStorage.setItem("expediboxApiUrl", "http://api.expedibox.host:8000");
  localStorage.setItem("expediboxLockerId", "7WJz7GqZ");
  localStorage.setItem(
    "expediboxApiKey",
    "e822134e-85f7-48bd-b27c-68999ea5d717"
  );
  localStorage.setItem("wsUrl", "ws://127.0.0.1:8001");
  localStorage.setItem("wsToken", "4b2971c3-a587-4709-8e56-b7a656d2b2c1");
} else if (window.location.search.includes("?test")) {
  localStorage.setItem("apiUrl", "https://distributeur.co/api");
  localStorage.setItem("apiKey", "63759ac0-929b-4304-baad-adf1be46c88a");
  localStorage.setItem("expediboxApiUrl", "https://api.expedibox.com");
  localStorage.setItem("expediboxLockerId", "MmD8W26G");
  localStorage.setItem(
    "expediboxApiKey",
    "859aff40-512b-4a9d-8161-cd12edf16a6f"
  );
  localStorage.setItem("wsUrl", "wss://ws.xpdbx.co");
  localStorage.setItem("wsToken", "");
} else {
  initConfig("apiUrl");
  initConfig("apiKey");
  initConfig("expediboxApiUrl");
  initConfig("expediboxApiKey");
  initConfig("expediboxLockerId");
  initConfig("wsUrl");
  initConfig("wsToken");
}
