interface TimerProps {
  milliseconds: number;
}

export function Timer({ milliseconds }: TimerProps) {
  const minutes = Math.floor(milliseconds / 1000 / 60);
  const seconds = Math.floor((milliseconds / 1000) % 60);

  return (
    <div className="text-muted">
      <i className="bi bi-clock me-2" />
      {String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}
    </div>
  );
}
