import { useEffect, useState, useCallback } from "react";
import { Button, Container } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { connect } from "../../ws";
import useOpenDoor from "../../hooks/useOpenDoor";
import { Footer } from "../../components/Footer";
import { useGetExpediboxLocker } from "../../hooks/useGetExpediboxLocker";

function Test() {
  const [wsConnection, setWsConnection] = useState<{
    close: () => void;
  } | null>(null);
  const [wsStatus, setWsStatus] = useState<"disconnected" | "connected">(
    "disconnected",
  );
  const openDoor = useOpenDoor();
  const history = useHistory();
  const { data: locker } = useGetExpediboxLocker();

  useEffect(() => {
    return () => {
      if (wsConnection) {
        wsConnection.close();
      }
    };
  }, [wsConnection]);

  const handleConnectWebSocket = useCallback(() => {
    if (wsConnection) {
      wsConnection.close();
      setWsConnection(null);
      setWsStatus("disconnected");
    } else {
      const connection = connect();
      if (connection) {
        setWsConnection(connection);
        setWsStatus("connected");
      }
    }
  }, [wsConnection]);

  const handleOpenDoor = async (position: string) => {
    try {
      await openDoor.mutate({ position });
    } catch (error) {
      console.error("Error opening door:", error);
    }
  };

  // Group doors by column
  const doorsByColumn =
    locker?.doors.reduce(
      (acc, door) => {
        const column = door.position[0];
        if (!acc[column]) {
          acc[column] = [];
        }
        acc[column].push(door);
        // Sort doors within column by row number
        acc[column].sort((a, b) => {
          const aRow = parseInt(a.position.substring(1));
          const bRow = parseInt(b.position.substring(1));
          return aRow - bRow;
        });
        return acc;
      },
      {} as Record<string, typeof locker.doors>,
    ) || {};

  return (
    <div className="p-4 h-100 overflow-auto">
      <div className="d-flex align-items-center mb-4">
        <Button
          variant="link"
          className="fs-3 text-decoration-none me-3"
          onClick={() => history.push("/")}
        >
          <i className="bi-arrow-left-circle-fill me-2"></i>
          Retour
        </Button>
        <h1 className="mb-0">Door Test</h1>
      </div>

      <Container className="mt-4">
        <Button
          className="me-3 mb-4"
          size="lg"
          variant={wsStatus === "connected" ? "success" : "primary"}
          onClick={handleConnectWebSocket}
        >
          <i
            className={`bi bi-${wsStatus === "connected" ? "plug-fill" : "plug"} me-2`}
          />
          {wsStatus === "connected" ? "Connected" : "Connect WebSocket"}
        </Button>

        {Object.entries(doorsByColumn).map(([column, doors]) => (
          <div key={column} className="mb-4">
            <h2 className="fs-4 mb-3">Column {column}</h2>
            <div className="d-flex flex-wrap">
              {doors.map((door) => (
                <Button
                  key={door.position}
                  className="me-3 mb-3"
                  size="lg"
                  onClick={() => handleOpenDoor(door.position)}
                >
                  Open {door.position}
                </Button>
              ))}
            </div>
          </div>
        ))}
      </Container>

      <Footer />
    </div>
  );
}

export default Test;
